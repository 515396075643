/* .opendetailsidebar {
  width: 20%;
  display: flex;
  flex-direction: column;
  height: 100vw;
  border-right: solid 0.7px #c9c9c9;
}
.opendetaillogin {
  padding: 4% 0%;
  margin-bottom: 30%;
  border-bottom: solid 2px #c9c9c9;
  display: flex;
  justify-content: flex-start;
  gap: 7%;
  position: relative;
  padding-left: 3%;
}
.login {
  height: 40px;
  width: 35px;
}
.logoutbtn {
 
  height: 35px;
  width: 35px;
  padding: none;
  background-color: transparent;
  border: none;
  cursor: pointer;
  align-self: center;
  margin-right: 1%;
}

.logouticon {
  height: 100%;
  width: 100%;
}
.logindetails {
  display: flex;
  flex-direction: column;

  justify-content: center;
  gap: 15%;
}

.logindetails p {
  margin-bottom: 0px;
  margin-top: 0px;
}

.login-name {
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-size: 1vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.login-id {
  color: #000;
  font-family: Inter;
  font-size: 14px;
  font-size: 1vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.navbaricon {
  position: absolute;
  top: 12%;
  margin-right: 10%;
}

.options {
  display: flex;
  position: relative;
  align-items: start;
  gap: 1%;
  height: fit-content;
  padding-left: 15%;

  
}
.options p {
  color: #000;
  font-family: Inter;
  font-size: 20px;
  font-size: 1.2vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: 13%;
  margin-top: 0px;
  margin-bottom: 8%;
}

.options p:hover {
  color: #0081df;
  cursor: pointer;
}

.admincatalogheader {
  width: 149px;
  height: 24px;
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  background-color: #ffffff !important  ;
  color: black !important;
} */

.admin-sidebar{
  background-color: #242320;
  padding-top: 25px;
  color: white;
  width: 50px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 22px;
  margin-top: 5vw;
  transition: 0.5s;
  z-index: 100;
  overflow: hidden;
  width: 200px;
  position: fixed; 
  left: -150px;
  height: 100vh;
  
}

.admin-sidebar:hover{
    width: 220px;
    transition: 0.5s ;
    align-items: flex-start;
    left: 0px;
}

.sidebar-login-details{
  display: flex;
  flex-direction: column;
  gap: 10px;
  width:fit-content;
  flex-wrap: wrap;
  width: 120px;
  margin-left: 10px;
}

.sidebar-login-id {
  color: white;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  height: fit-content;
  margin: 0px;
  word-break: break-all;
}
.sidebar-login-name  , .sidebar-logout-text{
  color: white;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 0px;
  height: fit-content;
  margin: 0px; 
}
.sidebar-logout-text{
  align-self :center;
  margin-left: 10px;
}

.sidebar-products ,.sidebar-quotation,.sidebar-vendors,.sidebar-users{
  display:flex  ;
  align-items:center;
   text-align: left; 
   justify-content: space-between;
   width: 100%;
}

.sidebar-products > p ,.sidebar-quotation >p ,.sidebar-vendors >p , .sidebar-users > p{
  color: white;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 0px;
  height: fit-content;
  cursor: pointer; 
  margin-left: 10px;
}

.logoutbtn {
  background-color: transparent;
  border: none;
  cursor: pointer;
  width: fit-content;
  margin-right: 2px;
  
}

.logouticon {
  height: 28px;
  width: 28px;
}
