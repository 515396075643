.quotation-request-details{
    border-radius: 7px 7px 0px 0px;
background: #498783;
box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
color: #FFF;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: normal;
padding: 2% 4%;
/* width: auto; */
}


/* table css */
.css-10botns-MuiInputBase-input-MuiFilledInput-input{
    padding: 12px !important;
    color: #0081df !important;
    font-weight: 400 !important;
    width: 70px;
    font-size: 12px;
}

  
/* Target the scrollbar in webkit-based browsers */
.custom-scrollbar::-webkit-scrollbar {
    background-color: red; /* width of the scrollbar */
  }
  
  /* Handle on hover */
  .custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background: #0081df;
  }
  
  /* Handle on active */
  .custom-scrollbar::-webkit-scrollbar-thumb:active {
    background: #0081df;
  }
  
  /* Track */
  .custom-scrollbar::-webkit-scrollbar-track {
    background: #fff;
  }
  
  /* Handle */
  .custom-scrollbar::-webkit-scrollbar-thumb {
    background: #0081df;
    border-radius: 8px;
  }


/* for description scroll bar */
  .css-o3i3ce-MuiFormControl-root-MuiTextField-root{
    overflow-y: hidden !important;
    padding: none !important;
    border:none !important;
  }
  

  .css-1u9des2-indicatorSeparator{
    display: none;
  }

  /* price list search bar */

 
  