.noaccess{
    position: absolute;
    left: 0%;
    top:8vw;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 8%;
    font-family: Inter;
}
.noaccess > svg{
    height: 80px;
    width: 80px;
}
.noaccess > h1{
    margin: 0;
    margin-top: 5%;
    
    font-size: 34px;
}
.noaccess > h3{
    font-size: 32px;
    /* margin-left: 4%; */
}
.noaccess > h4{
    font-weight: 500;
    font-size: 24px ;
    /* margin-left: 4%; */
    margin-top: 0;
}