@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@400;500&display=swap");
.wrapper-error-page{
    display: flex;
    justify-content: center;
    margin:3%;
    margin-top: 6%;
    top: 15%;
    left: 40%;
    position: absolute;
}
.error-page{
    font-family: Manrope;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.error-page > img{
    width: 450px;
    height: 330px;
    margin-bottom: 3%;
}
.error-page > h1{
    color:#F9AB43;
    font-size: 50px;
    font-weight: 700;
    margin-bottom: 1%;
}
.error-page > p{
    color:#6E7FDD;
    font-size: 25px;
    font-weight: 500;
}
