

.lsq-collapsible-heading{
 
  border-bottom: none !important;
  color: #00447D !important;
  font-family: 'Inter' !important;
  font-size: 16px !important;
  font-weight: 500 !important;
}

.lsq-data-cell > tr > td{
 
  text-align: center !important;
}



.custom-table-row{
  display: grid !important;
  grid-template-columns: 22%  30% 45% !important;
  grid-template-rows: repeat(5, auto) !important; 
  gap: 10px;
  border-radius: 3px;
  border: 0.7px solid #898989 !important;
  padding: 20px 10px;
  width: 100%;
  overflow-x: auto;
  column-gap: 10px;
}

.lsq-textfield{
  padding-left: 0px !important;
  margin-left: 0px !important;
}

/* search bar */
.lsq-textfield > div > input{
  height: 42px !important;
  padding: 0px  !important; 
  padding-left: 5px !important;
}

.lsq-table::-webkit-scrollbar {
  
  width: 10px; /* width of the scrollbar */
}

/* Handle on hover */
.lsq-table::-webkit-scrollbar-thumb:hover {
  background: #cdc8c8;
}

/* Handle on active */
.lsq-table::-webkit-scrollbar-thumb:active {
  background:#cdc8c8;
}

/* Track */
.lsq-table::-webkit-scrollbar-track {
  background: #fff;
}

/* Handle */
.lsq-table::-webkit-scrollbar-thumb {
  background: #cdc8c8;
  border-radius: 8px;
}


.sales-source{
  border-right : 1px solid rgb(203, 203, 203) !important;
  border-top-right-radius: 7px !important;
  border-bottom-right-radius: 7px !important;
}
