.pricelist-textfield{
  padding-left: 0px !important;
  margin-left: 0px !important;
}

.pricelist-textfield > div > input{
    height: 44px !important;
    padding: 0px  !important; 
    padding-left: 5px !important;
  }

  .sales-lead-textfield > div > input{
    height: 44px !important;
    padding: 0px  !important; 
    padding-left: 5px !important;
  }

  .pricelist-table::-webkit-scrollbar {
    background-color: red;
    width: 10px; /* width of the scrollbar */
  }
  
  /* Handle on hover */
  .pricelist-table::-webkit-scrollbar-thumb:hover {
    background: #cdc8c8;
  }
  
  /* Handle on active */
  .pricelist-table::-webkit-scrollbar-thumb:active {
    background:#cdc8c8;
  }
  
  /* Track */
  .pricelist-table::-webkit-scrollbar-track {
    background: #fff;
  }
  
  /* Handle */
  .pricelist-table::-webkit-scrollbar-thumb {
    background: #cdc8c8;
    border-radius: 8px;
  }