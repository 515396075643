.modalContainer {
  width: 35vw;
  height: auto;
  border-radius: 12px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  padding: 25px;
  backdrop-filter: blur(8px);
}

.addproductform {
  display: flex;
  width: auto;
  justify-content: space-evenly;
  position: absolute;
  top: -10%;
  left: 20%;
}

.modalContainer .title {
  display: inline-block;
  text-align: center;
  margin-top: 3px;
  margin-bottom: 5px;
}

.modalContainer .body {
  flex: 50%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  text-align: center;
  /* height: 25px; */
  padding-top: 4%;
}

.body input {
  font-size: 18px;
  width: 50%;
  height: 25px;
  padding-left: 8px;
  border-radius: 3px;
  border: 0.7px solid #c9c9c9;
}
.body select {
  border-radius: 3px;
  border: 0.7px solid #c9c9c9;
  width: 53%;
  padding-left: 5px;
  padding-right: 5px;
  
}
.body2 select {
  border-radius: 3px;
  border: 0.7px solid #c9c9c9;
  width: 100%;
  font-size: 15px;
  
}
.modalContainer .body2 {
  flex: 50%;
  height: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
  font-size: 18px;
  text-align: center;
}
#true {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  text-align: center;
  width: 120px;
}

.modalContainer .productfooter {
  flex: 20%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 15px;
  padding-left: 50px;
}

.adminupload {
  width: 100px;
  height: 45px;
  border: none;
  background-color: cornflowerblue;
  color: white;
  border-radius: 8px;
  font-size: 20px;
  cursor: pointer;
  margin-top: 30px;
  margin-left: 130px;
}

.modalContainer .productfooter button {
  width: 130px;
  height: 40px;
  border: none;
  background-color: cornflowerblue;
  color: white;
  border-radius: 8px;
  font-size: 20px;
  cursor: pointer;
  /* margin-top: 180px; */
  margin-top: 10px;

  margin-bottom: 5px;
  gap: 2%;
}
