.adminuser {
  width: 70%;
  position: absolute;
  left: 15%;
  top: 13%;
  font-family: Inter;
}
.access-container {
  border-radius: 7px;
  border: 0.7px solid #c9c9c9;
  padding: 4%;
  margin-top: 3%;
}
#userheader {
  margin-left: 40%;
  font-size: 30px;
}
#userheader2{
  margin: 0;
  margin-bottom: 2%;
  font-weight: 600;
}
.userinput {
  width: 46%;
  padding-left: 2%;
  height: 42px;
  flex-shrink: 0;
  border-radius: 7px;
  border: 0.7px solid #c9c9c9;
  background: #fff;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.userinput::placeholder{
  color:#C9C9C9;
  font-size: 19px ;
  font-weight: 100;
}

#userrole {
  width: 46%;
  height: 45px;
  padding-left: 2%;
  flex-shrink: 0;
  border-radius: 7px;
  border: 0.7px solid #c9c9c9;
  background: #fff;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 30px;
}
#userrole option{
  color: black;
}

.useraddbutton {
  width: 115px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 7px;
  cursor: pointer;
  background: #549b45;
  color: #fff;
  border: none;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: block;
  margin-top: 2%;
  margin-left: 84%;
  /* position: absolute;
  right: 0%;
  top: 25%; */
}

.usertable {
  max-height: 448px;
  overflow-y: auto;
  flex-shrink: 0;
  border-radius: 7px;
  border: 1px solid #c9c9c9;
  background: #fff;
  margin-top: 4%;
}
.usertable td
{
  padding: 3px;
}
.divider{
  width: 1px;
  color:#C9C9C9;
}
.usertablehead {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  border-bottom: 1px solid #C9C9C9;
}
.usertablehead > p {
  display: flex;
  margin: 0;
  margin-left: 10%;
  margin-top: 4%;
  margin-bottom: 5%;
  font-size: 20px;
  font-weight: 600;
}
.individual {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  border-bottom: 1px solid #F2F2F2;
}
.individual > p {
  display: flex;
  justify-content: flex-start;
  gap:5%;
  margin-left: 10%;
}
.individual > svg {
  position: absolute;
  right: -2%;
  margin-top: 1.8%;
}
/* .individual{
    display: flex;
} */

.editsvg {
  cursor: pointer;
}
.deletesvg {
  cursor: pointer;
}
.admindeletepopup {
  background-color: white;
  width: 35%;
  height: fit-content;
  padding: 2% 2% 4% 2%;
  position: fixed;
  top: 30%;
  left: 40%;
  border-radius: 5px;
  background: #fff;
}

.admindeletepopup .no {
  border-radius: 7px;
  background: #a80000;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 126px;
  height: 45px;
  flex-shrink: 0;
  cursor: pointer;
}

.admindeletepopup .yes {
  width: 23%;
  padding: 10px 10px;
  border-radius: 7px;
  background: #489a34;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 126px;
  height: 45px;
  flex-shrink: 0;
  margin-left: 10px;
  cursor: pointer;
}

.admindeletepopupbuttons {
  display: flex;
  justify-content: center;
}

.admindeletepopup p {
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
}

.admindeletepopup span {
  color: #000;
  font-family: Inter;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
}

.roledropdown{
    background-color: white;
    width:18vw;
    height: fit-content;
    border-radius: 5px;
    background: #FFF;
    padding: 10px;
    
}
.roledropdown > p {
  cursor: pointer;
  margin: 0;
  padding: 15px 20px;
  /* height: 38px; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: Inter;

}

.roledropdown > p:hover {
  border-radius: 5px;
  background: #bfe4ff;
}
