.add-blog-page{
    position: absolute;
    left: 24%;
    top: 14%;
    width:60%;
    font-family: Manrope;
    display: flex;
    flex-direction: column;
}
.add-blog-page > h2{
    font-size: 2.6vw;
    font-weight: 800;
    margin: 0;
    margin-bottom: 1%;
}
.add-blog-page p{
    font-size: 1.3vw;
    margin-bottom: 1%;
    font-weight: 500;
}
.add-blog-page input{
    width:100%;
    border: 0.7px solid #C9C9C9;
    border-radius: 7px;
    padding: 1.1% 1.5%;
    font-size: 1.3vw;
    font-weight: 500;
    margin-bottom: 1%;
}
.add-blog-page input:focus{
    outline: 1px solid #C9C9C9;
}
.add-blog-page textarea{
    width: 100%;
    border: 0.7px solid #C9C9C9;
    border-radius: 7px;
    padding: 1.1% 1.5%;
    font-size: 1.2vw;
    font-weight: 400;
    margin-bottom: 1%;
    resize: none;
    font-family: Manrope;
}
.add-blog-page textarea:focus{
    outline: 1px solid #C9C9C9;
}
.add-blog-page button{
    background-color: #098BC3;
    border: none;
    border-radius: 7px;
    color: white;
    font-size: 1.3vw;
    padding: 1.1% 4%;
    margin-bottom: 1%;
    margin-top: 0.5%;
    transition: all 0.2s ;
}
.remove-subsec{
    background-color: #A80000 !important;
    font-size: 1.3vw ;
    padding: 1.1% 4% ;
    position: absolute;
    right: -3%;
}
.post-blog-btn{
    padding: 1.3% 6% !important;
    font-size: 1.6vw !important;
    margin-bottom: 3% !important;
}
.add-blog-page button:hover{
    cursor: pointer;
    opacity: 0.7;
}
.blog-content{
    margin-bottom: 9%;
}

.add-subsec{
   margin-top: 2% !important;
   margin-left: 40%;
   padding: 1.2% 5% !important;
}
.add-cat-div > div {
    display: flex;
    align-items: center;
    gap: 2%;
}
.add-cat-btn{
    display: inline-flex !important;
    border: none;
    background-color: #098BC3;
    color: white;
    font-size: 2.5vw !important;
    border-radius: 50% !important;
    /* display: flex; */
    justify-content: center !important;
    align-items: center;
    width: 3vw;
    height: 3vw;
    padding: 0 !important;
}
