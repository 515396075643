input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number]{
    -moz-appearance: textfield;
}

.css-34nofg-MuiTableRow-root th ,.css-34nofg-MuiTableRow-root td {
    border-bottom: 0px !important;
    border-right: 0.7px solid #ECECEC !important;
    
}

@media print {
    body * {
      display: none !important;
    }
  }
  

/* .css-1q1u3t4-MuiTableRow-root th {
    padding: 5px !important;

}

.css-34nofg-MuiTableRow-root td{
    padding: 3px;
}


.css-1q1u3t4-MuiTableRow-root th{
    padding: 15px;
}


.css-apqrd9-MuiTableBody-root tr th{
    padding: 10px;
    margin-top: 2px;
}

.css-l4u8b9-MuiInputBase-root-MuiInput-root{
    height: 100% !important;
} */

.discount-payment-container{
    display: flex;
    /* gap: 10px; */
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 30px;

}
/* .css-rqglhn-MuiTable-root th{
    padding: 12px !important;
    
    
} */

.css-177gid-MuiTableCell-root {
    padding: 0px !important;
}


.attachfilecontainer{
    
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    width: 100%; 
}
.upload-file-heading{
    color: black;
    font-family:'Inter';
    font-weight: 800;
    font-size: 16px;
    line-height: 19.36px;
    align-self: center;
}


.attachfilecontainer > input[type=file]::file-selector-button {
    padding: 10px;
    border-radius: 5px;
    border: 2px solid #CFCFCF ;
    padding: 8px ;
    background-color: #489A34;
    border: none;
    cursor: pointer;
    color: white;
    min-width: 26%; 
  }

  .attachfilecontainerBtns > input[type=file]::file-selector-button {
    padding: 10px;
    border-radius: 5px;
    border: 2px solid #CFCFCF ;
    padding: 8px ;
    background-color: #1976D2;
    border: none;
    cursor: pointer;
    color: white;
    min-width: 26%; 
  }

.input-file{
    background-color: #F9F9F9;
    font-size: 14px;
    font-family: "Inter";
    font-weight: 400;
    border-radius: 3px;
    border: 0.5px solid #CFCFCF ;
    padding: 8px;
    width: 95%;   
}
.input-file:focus{
    outline: none;
}

.add-file-btn{
    background-color: #A80000;
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 600;
    border-radius: 5px;
    color: white;
    box-shadow: 0px 4px 4px 0px #787575;
    padding: 8px ;
    border: none;
    cursor: pointer;
    min-width: 19%;
}
.del-file-btn{
    background-color: #A80000;
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 600;
    border-radius: 5px;
    color: white;
    box-shadow: 0px 4px 4px 0px #787575;
    padding: 8px ;
    border: none;
    cursor: pointer;
    min-width: 19%;

}

.uploaded-files{
    background-color: #E0F2FF;
    display: flex;
    align-items: center;  
    gap: 10px;
    margin-bottom: 10px;
    width: fit-content;
    padding: 2px 10px;
    width: 94%;
    border-radius: 3px;
    position: relative;
}
.prev-uploaded-files{
    background-color: #E0F2FF;
    display: flex;
    align-items: center;  
    gap: 10px;
    margin-bottom: 10px;
    width: fit-content;
    padding: 2px 10px;
    width: 94%;
    border-radius: 3px;
    position: relative;
}



.css-6431qj-MuiDialogContent-root {
    height:800px;
}
