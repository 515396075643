.Catalog {
  display: flex;
  gap: 50px;
  align-items: stretch;
  margin-top: 50px;
  padding: none;
  padding-left: 50%;
}

.adminheader {
  background: #d0dedd;
  width: 100%;
  height: 4vw;
}

.opendetailsidebar {
  width: 15%;
  display: flex;
  flex-direction: column;
  /* gap: 50%; */
  height: 100vw;
  border-right: solid 0.7px #c9c9c9;
}



.login-id {
  color: #000;
  font-family: Inter;
  font-size: 1vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 2%;
}

.navbaricon {
  position: absolute;
  top: 12%;
  margin-right: 10%;
}

.options {
  display: flex;
  position: relative;
  align-items: flex-start;
  gap: 1%;
  height: fit-content;
  padding-left: 15%;

  /* background-color: red; */
}
.options p {
  color: #000;
  font-family: Inter;
  font-size: 20px;
  font-size: 1.2vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: 13%;
  margin-top: 0px;
  margin-bottom: 8%;
}

.options p:hover {
  color: #0081df;
  cursor: pointer;
}
.admincatalogcontainer{
  width: 350px;
  height: 250px;
  height: fit-content;
  top: 398px;
  border: 0.7px solid #c9c9c9;
  border-radius: 7px;
  border: 0.7px;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  font-family: Inter;
  text-align: left;
  margin-left: 2.5vw;
}
.admincatalogheader {
  width: 149px;
  height: 24px;
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  background-color: #ffffff !important  ;
  color: black !important;
}

.admincatalogcoloumn {
  max-width: 270px;
  
  height: 250px;
  height: fit-content;
  top: 398px;
  border: 0.7px solid #c9c9c9;
  border-radius: 7px;
  border: 0.7px;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  font-family: Inter;
  text-align: left;
  margin-left: 2.5vw;
  overflow: hidden;
  word-wrap: break-word;
}
.adminimgcontainer {
  /* padding-left: 50px; */
  height: fit-content;
}
.admincatalogproductimage {
  width: 250px;
  height: 25vw;
  width: 100%;
  margin-right: 10%;
  object-fit: contain;
  /* height: 100%; */
  /* top: 410px;
    left: 69px; */

  /* background-color: red; */
}
.admincatalogdetailtable {
  display: flex;
}
.custom-slider {
  max-width: 260px;
  margin: 0 auto;
}
.adminsearchbarcatalog {
  width: 500px;
  height: 45px;
  top: 165px;
  left: 474px;
  border-radius: 7px;
  border: 0.7px;
  position: absolute;
}

.adminCatalogSearch {
  top: 1000px;
  background-color: white;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 8px #ddd;
  border-radius: 10px;
  margin-top: 1rem;
  max-height: 300px;
  overflow-y: scroll;
  z-index: 1;
}

.adminCatalogSearchres:hover {
  background-color: #efefef;
  cursor: pointer;
}

.admincatalogtable {
  position: absolute;
  top:6vw;
  left:24%;
  width: 70%;
  /* top: 168px; */
  padding-top: 20px;
  /* left: 474px; */
  border-radius: 7px;
  border: 0.7px;
  border: 0.7px solid #c9c9c9;
  overflow-y: scroll;
  
}
.adminimageupload {
  width: 140px;
  height: 40px;
  margin-bottom: 10px;
  margin-left: 265px;
  margin-top: 2vw;
  background-color: #098bc3;
  cursor: pointer;
  border-radius: 7px;
  border: none;
  position: absolute;
  bottom: 0;
}
.adminimageuploadtext {
  color: #fff;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: #fff;
}

.admincatalogdetails {
  margin-top: 15%;
  margin-left: 30%;
}

.searchTerm {
  height: 100%;
}

.catalogsearchButton {
  height: 50px;
  width: 45px;
  text-align: center;
  justify-content: center;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.admindeleteimage {
  margin-left: 330px;
  cursor: pointer;
  width: 80px;
  height: 30px;
  border-radius: 5px;
  margin-top: 5px;
  background: #a80000;
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: none;
}

.admindescription {
  padding-left: 26px;
  padding-top: 5px;
  display: flex;
  gap: 15px;
}
.admindescription h4 {
  color: #000;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.admindescription h4 span {
  color: red;
}
.admindescarea {
  width: 620px;
  height: 100px;
  border: none;
  margin-top: 1.8vw;
  font-family: Manrope;
  font-size: 15px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
}
.admineditdesc {
  cursor: pointer;
}
.admineditdescheader {
  width: 805px;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  border-radius: 7px 7px 0px 0px;
  background: #5b9995;
  margin-top: 10px;
  text-align: center;
  color: #fff;
  margin-left: 20px;
}
.admineditdescarea {
  width: 800px;
  height: 200px;
  margin-left: 20px;
  margin-top: 10px;
}
.adminsubmitdesc {
  width: 140px;
  height: 40px;
  /* top: 1145px
    left: 1238px */
  margin-top: 30px;
  border-radius: 7px;
  margin-bottom: 25px;
  margin-left: 680px;
  background: #489a34;
  cursor: pointer;
  color: #fff;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border: none;
}

.admindetailcatalog {
  margin-left: 10px;
}
.admindetailcatalog > tr{
  display: grid;
  grid-template-columns: 130px 270px;
  
}
.slick-prev:before {
  color: black !important;
  position: absolute;
  left: -5px;
}
.slick-next:before {
  color: black !important;
  position: absolute;
  right: -5px;
}
.admincatalogrow {
  color: #000;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  
}
.adminimagebordercontainer {
  display: flex;
  flex-direction: column;
}
.adminimageborder {
  border-radius: 7px;
  padding-right: 10px;
  margin-left: 20px;
  width: 419px;
  border: 0.7px solid #c9c9c9;
  position: relative;
}
.admincatalogskubut {
  width: 127px;
  height: 45px;
  top: 100px;
  left: 1250px;
  position: absolute;
  flex-shrink: 0;
  border-radius: 7px;
  background: #098bc3;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border: none;
}
.catalogskubut {
  width: 100px;
  height: 45px;
  border: none;
  background-color: cornflowerblue;
  color: white;
  border-radius: 8px;
  font-size: 15px;
  cursor: pointer;
  margin-bottom: 5px;
}

.s {
  display: flex;
  flex-direction: column;
  padding-left: 5px;
}

.adminskubuttt {
  width: 147px;
  height: 55px;
  top: 165px;
  left: 1223px;
  position: absolute;
  cursor: pointer;
  border-radius: 7px;
  background-color: #098bc3;
}
.adminskubuttttext {
  width: 89px;
  height: 19px;
  top: 233px;
  left: 1292px;

  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
}

.adminplbuttt {
  width: 161px;
  height: 55px;
  top: 165px;
  position: absolute;
  left: 1030px;
  border-radius: 7px;
  background-color: #eaeaea;
  cursor: pointer;
}

.adminplbuttttext {
  width: 118px;
  height: 19px;
  top: 233px;
  left: 1122px;
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: #098bc3;
}

.admincatalogedit {
  justify-items: end;
}

.admindescfooterbutton {
  display: flex;
}

.adminclosedetailbutton {
  width: 140px;
  height: 40px;
  margin-top: 30px;
  border-radius: 7px;
  margin-bottom: 25px;
  margin-left: 500px;
  background: #6a6c6a;
  cursor: pointer;
  color: #fff;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border: none;
}

.admindonebutton {
  width: 140px;
  height: 40px;
  margin-top: 30px;
  border-radius: 7px;
  margin-bottom: 25px;
  margin-left: 30px;
  background: #489a34;
  cursor: pointer;
  color: #fff;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border: none;
}