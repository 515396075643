.imageuploadpopup {
  position: absolute;
  width: 584px;
  height: 508px;
  height: fit-content;
  top: 50%;
  left: 100%;
  padding-left: 20px;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  text-align: center;
  padding: 10px;
}
.blog-imageuploadpopup {
  position: absolute;
  width: 584px;
  height: 508px;
  height: fit-content;
  top: 15%;
  left: 65%;
  z-index: 500;
  padding-left: 20px;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  text-align: center;
  padding: 10px;  
}
.imageuploadpopup h5 {
  margin-right: 10%;
  margin-top: 10px;
  margin-bottom: 15px;
  color: #000;
  font-family: Inter;
  font-size: 24px;
  /* font-size: 2.5vw; */
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  /* margin-right: 30px; */
}
.blog-imageuploadpopup h5 {
  margin-right: 10%;
  margin-top: 10px;
  margin-bottom: 15px;
  color: #000;
  font-family: Inter;
  font-size: 24px;
  /* font-size: 2.5vw; */
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  /* margin-right: 30px; */
}

.adminimageuploadpic {
  width: 400px;
  height: 340px;
  margin-top: -30px;
}

.imagelarge {
  justify-content: space-evenly;
  width: 464px;
  height: 345px;
  height: fit-content;
  flex-shrink: 0;
  border-radius: 7px;
  border: 0.7px solid #c9c9c9;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding-top: 50px;
  padding-bottom: 10px;
  /* background-color: green; */
}
.adminuploadcontent {
  display: flex;
  gap: 10px;
}

.adminextraimage {
  height: 404px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  gap: 10px;
}

.uploadlabel {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5%;

  border: 2px solid #ccc;
  cursor: pointer;
  text-align: center;
  margin-left: 1vw;
  transition: all 0.3s ease;

  border-radius: 7px;
  background: #098bc3;
  width: 145px;
  height: 40px;
  flex-shrink: 0;
  color: #fff;
  font-family: Inter;
  font-size: 20px;
  /* font-size: 2.5vw; */
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.uploadicon {
  height: 30px;
  width: 30px;
}

.imagesmall {
  width: 69px;
  height: 69px;
  /* top: 301px
    left: 1025px */
  border-radius: 7px;
  border: 0.7px solid #c9c9c9;
}

/* input[type="file"] {
  margin: 10px 0;
} */

.adminpopupfooter {
  display: flex;
  gap: 7%;
  width: 100%;
  margin-top: 10px;
  /* margin-left:    350px; */
  justify-content: center;
}

.adminbuttonsubmit {
  background-color: #d9d9d9;
  color: #fff;
  padding: 5px;
  border: none;
  width: 130px;
  cursor: pointer;
  color: #a8a0a0;
  font-family: Inter;
  font-size: 20px;
  /* font-size: 2.5vw; */
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 7px;
}

.imagesmall img {
  height: 69px;
  width: 69px;
}

.adminbuttonsubmit:hover {
  background-color: #0056b3;
}
