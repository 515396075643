.admin-header{
    width: 100%;
    height: 5vw;
    background-color: #438ea3;
    
    /* padding: 0.5%; */
    display: flex;
    align-items: center;
    z-index: 100;
    position: fixed;
    gap:32%;
    
}

.admin-header img{
    width: 138px;
    height: 42px;
    margin-left: 1%;
    flex-shrink: 0;
    cursor: pointer;
}

.admin-header h1{

    position: relative;
    font-family: 'Inter','Helvetica',sans-serif;
    font-size: 32px;
    font-weight: 600;
    color: white;
}