@import url("https://fonts.googleapis.com/css2?family=Inter:wght@500&family=Manrope:wght@600&display=swap");
.productlistpopup {
  background-color: white;
  width: 35%;
  height: fit-content;
  padding: 2% 2% 4% 2%;
  position: fixed;
  top: 40%;
  left: 40%;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  z-index: 55555555555555555555555;
}

.productlistpopup .no {
  border-radius: 7px;
  background: #a80000;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 126px;
  height: 45px;
  flex-shrink: 0;
  cursor: pointer;
}

.productlistpopup .yes {
  width: 23%;
  padding: 10px 10px;
  border-radius: 7px;
  background: #489a34;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 126px;
  height: 45px;
  flex-shrink: 0;
  margin-left: 10px;
  cursor: pointer;
}

.productlistpopupbuttons {
  display: flex;
  justify-content: center;
}

.productlistpopup p {
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
}

.productlistpopup span {
  color: #000;
  font-family: Inter;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
}
.AdminProductList {
  /* margin-left: 2vw; */
  font-family: Inter;
  padding-right: 0px;
  width: 75%;
  position: absolute;
  top: 16%;
  left: 16%;
  padding-bottom: 5%;
  /* right: 0%; */
}
.adminheading {
  font-size: 2.2vw;
  margin-top: 3%;
  margin-left: 35%;
}
.adminflexbut {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
}
.adminsearchbar {
  width: 43vw;
  height: 40px;
  padding-left: 1%;
  margin-top: 2%;
  border: 0.7px solid #c9c9c9;
  border-radius: 7px ;
  font-size: 16px;
}
.adminsearchbar:focus {
  border: none;
  outline: 1px solid #0671bf;
}
.adminsearchbar::placeholder {
  font-size: 16px;
  color: #c9c9c9;
}
.adminserachbarresults {
  position: absolute;
  top: 100%;
  left: 0;
  width: 56%;
  margin-top: 0.5%;
  border: 0.7px solid #c9c9c9;
  z-index: 100;
  background-color: white;
  border-radius: 7px;
}
.adminserachbarresults div {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 10px;
  padding: 0;
  padding-left: 1vw;
}
.adminserachbarresults div:hover {
  background-color: #efefef;
  cursor: pointer;
}
.adminsearchbut {
  width: 119px;
  height: 55px;
  font-size: 20px;
  font-weight: 300;
  margin-top: 2%;
  border: none;
  border-radius: 0px 7px 7px 0px;
  background: #0671bf;
}

.adminsearchbuttontext {
  width: 67px;
  height: 24px;
  top: 230px;
  left: 935px;
  cursor: pointer;
  font-family: Inter;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
}
.adminpcbut {
  width: 161px;
  height: 55px;
  border: none;
  margin-top: 2%;
  margin-left: 4%;
  border-radius: 7px;
  background-color: #eaeaea;
  cursor: pointer;
}

.adminpcbuttontext {
  width: 118px;
  height: 19px;
  top: 233px;
  left: 1122px;
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: #098bc3;
}
.adminskubut {
  width: 127px;
  height: 40px;
  margin-top: 2%;
  border: none;
  margin-left: 7%;
  cursor: pointer;
  border-radius: 7px;
  background-color: #098bc3;
}
.adminskubuttontext {
  width: 89px;
  height: 19px;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
}
.admintable {
  margin-top: 2%;
  margin-bottom: 5%;
  border-radius: 7px;
  width: 100%;
}
.admintable td {
  width: 10vw;
}
.admintablerow {
  width: 100%;
  height: 4vw;
  border-radius: 7px 7px 0px 0px;
  z-index: 1;
  background-color: #5b9995;
}

.admintablerow th {
  width: 1vw;
  font-size: 18px;
  text-align: center;
  font-weight: 700;
  padding: 0px;
  color: #fafafa;
  font-weight: bold;
  align-self: center;
}

.admindetailheader {
  text-align: center;
  font-weight: normal;
  padding: 1vw;
  cursor: pointer;
  transition: all 0.3s ease;
  /* color: #0671bf; */
  font-size: 16px;
  font-weight: 400;
}
/* .admintablerow > th{
  width:8vw;
} */
.admindetailheader {
  cursor: pointer;
  transition: all 0.3s ease;
  /* color: #0671bf; */
}
/* .admindetailheader:hover {
  transform: scale(1.22);Fp
} */
.admintablerow2 {
  border-radius: 7px;
  border: 0.7px;
  background-color: #f7f7f7;
  border-radius: 7px;
  overflow: scroll;
}
.admintablerow2 > td:first-child{
  color:#0671BF;
}
.admintablerow2 > td:nth-child(2){
    width: 18vw;
}
.admintablerow2 > td:nth-child(4){
  max-width: 16vw;
  overflow: hidden;
  word-wrap: break-word;
}

.adminedit {
  cursor: pointer;
}

.upload-label {
  background-color: #f0f0f0;
  padding: 5px 10px;
  border: 2px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  margin-left: 1vw;
  transition: all 0.3s ease;
}

.upload-label:hover {
  border-color: #007bff;
}
.file-input {
  display: none; /* Hide the file input */
}

#fileInput {
  height: 5px;
  width: 20px;
}
.file-name {
  margin-top: 1vw;
  margin-bottom: 5px;
  margin-left: 3vw;
}
.upload-button {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  margin-top: 0px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
}
.upload-button:hover {
  background-color: #0056b3;
}

.cataloguingHeader {
  cursor: pointer;
}

.adminvisible {
  cursor: pointer;
  padding-left: 0.3vw;
}
.react-switch-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 50px;
  height: 25px;
  margin-left: 40px;
  border-radius: 100px;
  position: relative;
  transition: background-color 0.2s;
}

/* Style the switch button */
.react-switch-label .react-switch-button {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  transition: 0.2s;
  background: #fff;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}
.react-switch-checkbox:checked + .react-switch-label .react-switch-button {
  left: calc(100% - 2px);
  transform: translateX(-100%);
}
.react-switch-checkbox {
  height: 20px;
  width: 20px;
  margin-left: 40%;
}
.react-switch-checkbox:hover {
  cursor: pointer;
}

.react-switch-label:active .react-switch-button {
  width: 60px;
}

#product {
  width: 263px;
  height: 55px;
  /* top: 295px;
  left: 556px; */
  border-radius: 7px;
  border: 0.7px;
}
#brand {
  width: 236px;
  height: 55px;
  /* top: 295px;
  left: 844px; */
  border-radius: 7px;
  border: 0.7px;
}
#dummy-select  {
  width: 236px;
  height: 55px;
  /* top: 295px;
  left: 556px; */
  border-radius: 7px;
  border: 0.7px;
}



.adminfiltersort {
  display: flex;
  flex-direction: row;
  gap: 2vw;
  margin-top: 1.7vw;
  /* top: 295px;
  left: 556px; */
  /* position: absolute; */
}
.adminfilter {
  /* width: 55px;
  height: 24px; */
  /* top: 310px;
  left: 474px;
  position: absolute; */
  font-family: Inter;
  font-size: 20px;
  font-weight: 500;
  margin: 0;
  margin-top: 0.5vw;
  /* line-height: 24px; */
  letter-spacing: 0em;
  text-align: left;
}

.clearfilterButton {
  border: 1px solid #c3bebe; /* Border style */
  background: none;
  color: #787676;
  font-size: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.3rem;
  height: 34px; /* Adjust the height to match the React Select */
  width: 34px; /* Optional: Set a width for consistency */
  border-radius: 10%; /* Makes the button circular */

  margin-top: 0.09rem;
}
.clearfilterButton:hover {
  color: rgb(0, 140, 200); /* Change color on hover */
}





.adminsortlabel {
  left: 20%;
}
.adminsortbutton {
  cursor: pointer;
}
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
  background-color: #dc3545;
}
.success-message {
  color: #28a745;
}

.error-message {
  color: #dc3545;
}

/* .adminsortbutton{
  margin-right: 20%;
} */

/* .admintablerow2 .td{
/* justify-content: start; */
/* margin-left: 100px; */
/* padding-top: 30px; */
/* margin-top: 50px; */

/* .admintablerow2 td,
.admintablerow2 th {
  padding: 10px 16px;
  text-align: center;
}
.admintablerow2 th {
  background-color: #000000;
  color: #fafafa;
  font-family: 'Open Sans', Sans-serif;
  font-weight: bold;
  align-self: center;
} */

/* tr {
    width: 100%;
    font-family: 'Montserrat', sans-serif;
  } */

/* .disabled-row {
    pointer-events: none; 
    opacity: 0.6; 
  } */

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.paginationbutton {
  background-color: #0671bf;
  color: white;
  width: 60px;
  height: 30px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  font-size: 15px;
}