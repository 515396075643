.close {
  size: 150%;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000 !important;
  pointer-events: auto;
}

.modalBackground {
  width: 30vw;

  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
}

.brandmodalContainer {
  width: 500px;
  height: 300px;
  /* top: 150px;
  left: 600px; */
  top:8%;
  left: 15%;
  position: absolute;
  border-radius: 12px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 25px;
  backdrop-filter: blur(8px);
}

.brandmodalContainer .title {
  display: inline-block;
  text-align: center;
  margin-top: 10px;
}

.brandmodalContainer .body {
  flex: 50%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.2rem;
  text-align: center;
  margin: 25px;
  gap: 4%;
}

.body input {
  width: 20vw;
  height: 30px;
}
#brandName{
  height: 2.5vw;
}
.body select {
  width: 50%;
  height: 25px;
}

.brandmodalContainer .body2 {
  flex: 50%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 50px;
  font-size: 1.2rem;
  text-align: center;
  gap: 10.5%;
  margin-left: 25px;
}
.brandmodalContainer select{
  width: 50%;
}

#yes {
  flex: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  text-align: center;
  width: 14.8vw;
  height: 2.7vw;
}

.brandmodalContainer .brandfooter {
  flex: 20%;
  margin-left: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5%;
}

.brandmodalContainer .brandfooter button {
  width: 120px;
  height: 40px;
  border: none;
  background-color: cornflowerblue;
  color: white;
  border-radius: 8px;
  font-size: 20px;
  cursor: pointer;
  /* margin-top: 180px; */
  margin-top: 50px;

  margin-bottom: 5px;
  gap: 10px;
}
