/* @import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;500&display=swap"); */
.admin-loginpage {
  /* margin: 6% 5% 8%; */

  font-family: Inter;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0%;
  top: 10%;
  width: 100%;
}
.admin-loginpage > h1 {
  color: #000;
  font-family: Inter;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 1.5%;
}
.admin-loginpage > h4 {
  color: #000;
  font-family: Inter;
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 1.5%;
  margin-top: 1%;
}
.admin-loginpage > img {
  width: 25%;
  aspect-ratio: 1.023;
  margin-bottom: 2.5%;
}
.admin-loginpage > button {
  border-radius: 7px;
  border: 0.7px solid #c9c9c9;
  background-color: transparent;
  display: flex;
  width: 35%;
  gap: 2.5%;
  color: #434343;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  justify-content: center;
  align-items: center;
  padding: 0.7% 0%;
  transition: all 0.2s;
}
.admin-loginpage > button:hover {
  cursor: pointer;
  background-color: #f2f1f1;
}
.admin-loginpage > button > svg {
  width: 25px;
  height: 26px;
}
